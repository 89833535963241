import React, { useState } from 'react';
import { Avatar, List, Image, Checkbox, Popover, Tag } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

import { BASE_URL } from '../../../constants';
import { postData } from '../../../services/HomeService';

const App = (props) => {
  const {
    profiles: vendorData,
    user,
    vendorSelections,
    selectAll,
    setProfiles,
    setSelectAll,
    setVendorSelections,
    setAllSelectedVendorId
  } = props;

  const [showPopup, setShowPopup] = useState(false);

  const handleSelectToggle = (_id) => {
    const updatedVendorSelections = {
      ...vendorSelections,
      [_id]: !vendorSelections[_id],
    };
    const allSelected = vendorData.every((vendor) => updatedVendorSelections[vendor._id]);
    const selectedVendorIds = Object.keys(updatedVendorSelections).filter(_id => updatedVendorSelections[_id]);

    if (!updatedVendorSelections[_id]) {
      delete updatedVendorSelections[_id];
    }
    setVendorSelections(updatedVendorSelections);
    setAllSelectedVendorId(selectedVendorIds);
    setSelectAll(allSelected);
  };

  const handleStarClick = async (profile) => {
    const payload = { profileIds: [profile._id] }
    const response = await postData('/profiles/updatestar/unstar', payload);

    const temp = vendorData.filter(val => val._id !== profile._id);
    setProfiles(temp);
  }

  const handleDelete = (record) => {
    const selectedProfile = vendorData.find((profile, index) => profile.name == record.name);
    handleStarClick(selectedProfile);
  }

  const handleTrippleDot = (item, visible) => {
    if (visible) setShowPopup(item)
    else setShowPopup(null);
  };

  return (
    <section className="ListComponent_container" style={{ width: '100%' }}>
      <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={vendorData}
        renderItem={(item, index) => (
          <List.Item key={index}>
            <div className="ListComponent_box2">

              <div className="ListComponent_box2">
                <div className="ListComponent_box1" style={{ minWidth: '296px' }}>
                  <Checkbox
                    checked={(vendorSelections ? vendorSelections[item._id] : false) || selectAll}
                    onChange={() => handleSelectToggle(item._id)}
                  />
                  {
                    item.avatar.url ?
                      <Image
                        src={item.avatar.url}
                        style={{
                          width: '32px',
                          height: '32px',
                          borderRadius: '50%'
                        }}
                      />
                      :
                      <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}>
                        {item.name[0]?.toUpperCase()}
                      </Avatar>
                  }
                  <div className="ListComponent_qt">
                    <h3>{item.name && item.name.length > 20 ? `${item.name.slice(0, 20)}...` : item.name}</h3>
                    <Tag
                      style={{ border: 'none' }}
                      color={item.status == "active" ? "green" : "red"}
                    >
                      {item.status}
                    </Tag>
                  </div>
                </div>
              </div>

              <div className='ListComponent_subCategory'>
                {item.primeLocation}
              </div>

              <div className='ListComponent_subCategory'>
                {
                  item.tags.map(tag => (
                    <Tag color={tag.length > 5 ? 'geekblue' : 'green'}>
                      {tag.toUpperCase()}
                    </Tag>
                  ))
                }
              </div>

              <Popover
                content={
                  <div className="list_popup">
                    <span onClick={() => handleDelete(item)}>
                      Remove
                    </span>
                  </div>
                }
                overlayClassName="list_popover"
                trigger="click"
                placement="bottomLeft"
                open={showPopup === item}
                onOpenChange={(visible) => handleTrippleDot(item, visible)}
              >
                <MoreOutlined className="ListComponent_moreIcon" />
              </Popover>

            </div>
          </List.Item>
        )}
      />
    </section>
  )
}
export default App;