
import { showErrorMessage, showSuccessMessage } from "../utils";
import { deleteData , postData} from "./HomeService";

export const handleVendorAction = async (id, action) => {
    if (!id || !action) return { success: false };

    let response;
    switch (action) {
        case 'remove':
        case 'MultipleRemove':
            const payload = action === 'remove' ? { "ids": [id] } : { "ids": id };
            response = await deleteData(`/relations/disable-many`, payload);
            if (response.success) {
                showSuccessMessage("Removed successfully");
                return { success: true };
            } else {
                showErrorMessage(response.message);
            }
            break;
        case 'kyc':
            const kycResponse = await postData(`/partners/kyc/${id}`);
            if (kycResponse.success) {
                showSuccessMessage("KYC submitted successfully");
                return { success: true };
            } else {
                showErrorMessage(kycResponse.message);
            }
            break;
        case 'lock-profile':
            const partnerId = id?.sinkPartnerId;
            const locked = id?.locked;
            if (!locked) {
                showErrorMessage("Profile is yet not published");
                return { success: false };
            }
            const lockResponse = await postData(`/profiles/lock/${partnerId}`, { locked: !locked });
            if (lockResponse.success) {
                showSuccessMessage("Profile locked successfully");
                return { success: true };
            } else {
                showErrorMessage(lockResponse.message);
            }
            break;
        default:
            console.error('Invalid action:', action);
            break;
    }
    return { success: false };
};