import React, { useState, useEffect } from 'react';
import BreadcrumbComponent from '../common/BreadcrumbComponent';
import { Alert, Avatar, Button, message } from 'antd';
import './style.css';
import FormRegistration from '../common/FormRegistration';
import { insertApiCall, formTemplate, updateApiCall } from '../../services/RegistrationService';
import { fetchData } from '../../services/HomeService';
import _ from 'lodash';
import { ACCOUNT_CTA_MAP, COPY_MESSAGE } from '../../constants';

import { useSelector, useDispatch } from "react-redux";
import { updateUser } from '../../helpers/userSlice';
import { checkEmptyFields, checkRegexInValidation, showErrorMessage } from '../../utils';
import { CloseOutlined, ExclamationCircleFilled } from '@ant-design/icons';

const SOURCE_ENTITY = 'users';

export default function EnterpriseData({ selectedParent, selectedChild, AvatarColor }) {
    const [formTemplateData, setFormTemplateData] = useState({});
    const [formPrefillData, setFormPrefillData] = useState(null);
    const [showAlertNudge, setShowAlertNudge] = useState(true);
    const [isFormDataValidated, setIsFormDataValidated]= useState(false);

    const userData = useSelector((state) => state.user); 
    const dispatch = useDispatch();

    const [orgId, setOrgId] = useState(_.get(userData, 'org', ''));

    useEffect(() => {
        if(orgId) fetchTemplate();
    }, [orgId]);
    const fetchItems = async (pid) => {
        const data = pid? await fetchData(`/partners/${pid}`): null;
        if (data && !_.isEmpty(data)) {
            setFormPrefillData(data);
        }    
    };

    useEffect(() => {
        if (orgId) {
            fetchItems(orgId);
        }
    }, [orgId]);

    const makeApiCallAndSetStatus = async (requestData, source_entity, pid='') => {
        const { business_brand_name } = requestData;
        try {
            const apiCallFunction = formPrefillData ? updateApiCall : insertApiCall;
            const { success, id, message:msg='' } = await apiCallFunction({requestData, source_entity, pid, registerType: 'vendor'});
            if (success) {
                message.success(msg || 'Information updated successfully!');
                if(!orgId && id) {
                    setOrgId(id);
                    dispatch(updateUser({org: id}))
                }
                if (business_brand_name) {
                    dispatch(updateUser({ orgName: business_brand_name }));
                }
            } else {
                message.error(msg || 'Failed to update information');
                if(orgId) fetchItems(orgId)
            }
        } catch (error) {
            message.error('An error occurred while updating information.');
        }
    };
    
    const handleUpdateInformation = async () => {

        // if (checkEmptyFields(formTemplateData)) {
        //     setIsFormDataValidated(true); 
        //     showErrorMessage('Some required fields are missing');
        //     return;
        // } else {
        //     setIsFormDataValidated(false);
        // }
  
        // if (checkRegexInValidation(formTemplateData)) {
        //     setIsFormDataValidated(true);
        //     showErrorMessage('Some fields have invalid inputs')
        //     return
        // } else {
        //     setIsFormDataValidated(false);
        // }

        const requestPayload = await formTemplate(_.mapValues(formTemplateData, 'fieldValue'), SOURCE_ENTITY, 'Account Name', '');
        await makeApiCallAndSetStatus(requestPayload, SOURCE_ENTITY, formPrefillData ? orgId: '');
    };
    
    const fetchTemplate = () => {
        fetchData(`/partners/register/vendor?pid=${orgId}`)
            .then((resp) => {
                setFormTemplateData(resp.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleInputFieldChange = async (index, formData) => {
        setFormTemplateData(formData);
    };

    const handleCopyInfo = () => {
        const copiedText = _.map(formTemplateData, ({ fieldLabel, fieldValue }) => `${fieldLabel} : ${fieldValue}`).join('\n');
        navigator.clipboard.writeText(copiedText);
        message.success(COPY_MESSAGE, 1.5);
    };


    return (
        <section className='Home_container'>
            <section className="Home_firstContainer">
                <BreadcrumbComponent selectedParent={selectedParent} selectedChild={selectedChild} />
                <div className="Home_heading">
                    <h1>{selectedChild}</h1>
                </div>
            </section>
            <section className='account_container-2'>
                    <div className="ProfileView_alertContainer">
                        <Alert
                            message={
                                    <div className="ProfileView_alertIcon">
                                        <ExclamationCircleFilled style={{ color: '#FAAD14' }} /> 
                                        Important: Some account information has been filled by the enterprise, please verify and make rectifications wherever required
                                    </div>
                            }
                            type={'error'}
                            description=<CloseOutlined  onClick={() => setShowAlertNudge(false)}/>
                        />
                    </div>
                </section> 
            <main className='VendorOnboard_adding-1'>
                <FormRegistration formIndex={0} formTemplateData={formTemplateData} onFieldChange={handleInputFieldChange} formPrefillData={formPrefillData} formDataValidation={isFormDataValidated} formStyle={{ padding: '24px' }} formFormatLong={true} partnerID={orgId} fetchItems={fetchItems} />
            </main>
            <div className='EnterpriseInfo_Button'>
            <Button style={userData?.orgType === 'vendor' ? { background: '#008065', border: '1px solid #008065' } : {}} onClick={handleUpdateInformation}>Update Information</Button>
            </div>
        </section>
    );
}
