import React, { useState } from 'react';
import { Button, Checkbox, Divider, Form, Modal, Radio } from 'antd';

import postAskQuoteCover from '../../../assets/dashboard-img/ask-quote.gif';

import rectLeft1 from '../../../assets/dashboard-img/rect-left-1.svg';
import rectLeft2 from '../../../assets/dashboard-img/rect-left-2.svg';
import { postData } from '../../../services/HomeService';
import { showErrorMessage, showSuccessMessage } from '../../../utils';


export default function PostAskQuoteModal({ isVisible, postQuoteData, onClose }) {
    const [selectedSensitivity, setSelectedSensitivity] = useState([]);
    const [deliverTo, setDeliverTo] = useState('');
    const [packaging, setPackaging] = useState('');

    const handleSensitivityUpdate = (newSelection) => {
        if (newSelection.length > 2) return;
        setSelectedSensitivity(newSelection);
    }

    const handleSubmit = async () => {
        if (selectedSensitivity.length == 0) {
            showErrorMessage("Please select sensitivity!");
            return;
        }

        const payload = {
            sensitivity: selectedSensitivity,
            quoteId: postQuoteData.quoteId,
            partnerIds: postQuoteData.partnerIds,
            delivery: deliverTo,
            packaging: packaging
        }

        const res = await postData('/notifications/set-quote-preferences', payload);
        if (res === null) {
            showErrorMessage("Something Went Wrong!");
        }
        else {
            showSuccessMessage("Response Submitted!");
            onClose();
        }
    }

    return (
        <Modal
            open={isVisible}
            onCancel={onClose}
            footer={null}
            style={{
                maxWidth: '492px',
                padding: 0
            }}
            className='postQuoteModal_modal'
        >
            <div className='postQuoteModal_imgContainer'>
                <img className='postQuoteModal_imgBg1' src={rectLeft1} />
                <img className='postQuoteModal_imgBg2' src={rectLeft2} />

                <img className='postQuoteModal_imgBg3' src={rectLeft1} />
                <img className='postQuoteModal_imgBg4' src={rectLeft2} />

                <img className='postQuoteModal_imgCover' src={postAskQuoteCover} />
                <div>
                    <h4>Get the best deals!</h4>
                    <p>
                        Reach out to more verified <br />
                        vendors on our network
                    </p>
                    <a href="">
                        Know more
                    </a>
                </div>
            </div>
            <div className='postQuoteModal_body'>
                <h4>Add preferences</h4>
                <p>Please provide your preferences to get matched with the right vendors</p>
                <div className="postQuoteModal_formItem">
                    <div>
                        <p className='postQuoteModal_label'>Sensitivity <span>*</span>:</p>
                        <p className='postQuoteModal_sublabel'>(Max 2 allowed )</p>
                    </div>
                    <div>
                        <Checkbox.Group
                            value={selectedSensitivity}
                            onChange={handleSensitivityUpdate}
                        >
                            <Checkbox value="price">Price</Checkbox>
                            <Checkbox value="time">Time</Checkbox>
                            <Checkbox value="quality">Quality</Checkbox>
                        </Checkbox.Group>
                    </div>
                </div>

                <div className="postQuoteModal_formItem">
                    <div>
                        <p className='postQuoteModal_label'>Deliver to :</p>
                    </div>
                    <div>
                        <Radio.Group
                            value={deliverTo}
                            onChange={(e) => setDeliverTo(e.target.value)}
                        >
                            <Radio value="office">Office</Radio>
                            <Radio value="individual">Individual</Radio>
                        </Radio.Group>
                    </div>
                </div>

                <div className="postQuoteModal_formItem">
                    <div>
                        <p className='postQuoteModal_label'>Packaging :</p>
                    </div>
                    <div>
                        <Radio.Group
                            value={packaging}
                            onChange={(e) => setPackaging(e.target.value)}
                        >
                            <Radio value="included">Included</Radio>
                            <Radio value="excluded">Excluded</Radio>
                        </Radio.Group>
                    </div>
                </div>
            </div>
            <Divider style={{ margin: 0 }} />
            {/* <div classame='postQuoteModal_divider'>&nbsp;</div> */}
            <div className='postQuoteModal_footer'>
                <p onClick={onClose}>
                    Discard
                </p>
                <Button
                    type="primary"
                    onClick={handleSubmit}
                >
                    Post
                </Button>
            </div>
        </Modal>
    )
}
