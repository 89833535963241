import { MoreOutlined, PaperClipOutlined, SmileOutlined } from "@ant-design/icons";
import { Button, Typography, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useRef, useState } from "react";
import useClickOutSide from "../../../helpers/useClickOutSide";
import Picker from "emoji-picker-react";

export default function QuoteNotes({type, notes, text, setText, uploadProps, addNote}) {
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [cursorPosition, setCursorPosition] = useState();
    const textRef = useRef(null);
    const emojiRef = useRef(null);

    useEffect(() => {
        if (textRef.current && cursorPosition !== null) {
          textRef.current.selectionEnd = cursorPosition;
        }
      }, [cursorPosition]);
    
      useClickOutSide(emojiRef, () => {
        setShowEmojiPicker(false);
      });

    const handleEmoji = ({ emoji }) => {
        const ref = textRef.current;
        const textLength = text.length;
        const newText = text + emoji;
        setText(newText);
        const newCursorPosition = textLength + emoji.length;
        setCursorPosition(newCursorPosition);
        ref.setSelectionRange(newCursorPosition, newCursorPosition);
      };
  return (
    <section className="Quotation_section">
      <div className="Quotation_subtitle">
        <span>{type === 'ListApprovals'? 'Comment History' : 'Added notes' }</span>
      </div>
      <Typography>
        {notes.length > 0 ? (
          notes.map((note, index) => (
            <div className="Quotation_message" key={index}>
              <div className="Quotation_typoTitle">
                <span>
                  {note.userName} &#8226;{" "}
                  {new Date(note.createdAt).toLocaleDateString("en-IN")}
                </span>
                <MoreOutlined className="ListComponent_moreIcon" />
              </div>
              <span>{note.text}</span>
            </div>
          ))
        ) : (
          <span className="Quotation_nofile notes">
            Added notes will appear here
          </span>
        )}
      </Typography>
            <div className="Quotation_subtitle">
            <span>{type === 'ListApprovals'? 'New Comment' : 'New Note'}</span>
          </div>
        <>
            <TextArea
              rows={3}
              ref={textRef}
              placeholder="Type or paste here"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <div className="Quotation_inputFooter">
              <div className="Quotation_inputFeature">
                <i>
                  <Upload listType="picture" {...uploadProps}>
                    <PaperClipOutlined />
                  </Upload>
                  <hr />
                  <div
                    style={{
                      position: "absolute",
                      bottom: "196px",
                      left: "24px",
                    }}
                    ref={emojiRef}
                  >
                    {showEmojiPicker && <Picker onEmojiClick={handleEmoji} />}
                  </div>
                  <SmileOutlined
                    onClick={() => {
                      setShowEmojiPicker((prev) => !prev);
                    }}
                  />
                </i>
                <Button
                  onClick={addNote}
                  disabled={text.trim() === ""}
                  className={`add-note-btn ${text.trim() === "" ? "disabled" : ""}`}
                >
                  &#43; Add
                </Button>
              </div>
            </div>
        </>
    </section>
  );
}
