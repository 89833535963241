import {
  Button,
  Modal,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import "./style.css";
import {
  SelectOutlined,
  ToTopOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { BASE_URL } from "../../../constants";
import { showErrorMessage, parseToListObj, showSuccessMessage, showLoader } from "../../../utils";
import dayjs from "dayjs";
import {
  getComments,
  getFiles,
  getStatus,
  postComment,
  postItems,
} from "./quotationService";
import ModalHistoryComponent from "./ModalHistoryComponent";
import RequestNudgeComponent from "../../nudge/RequestNudgeComponent";
import _, { set } from "lodash";
import { postData } from "../../../services/HomeService";
import TableComponent from "./TableComponent";
import QuoteAttachment from "./QuoteAttachment";
import QuoteNotes from "./QuoteNotes";
import ModalRejectComponent from "../../common/ModalRejectComponent";
import { fetchData } from "../../../services/HomeService";

export default function ModalQuotationForm({
  isVisible,
  onClose,
  item,
  quoteData,
  handleFileUpload,
  AvatarColor,
  isSaving,
  setIsSaving,
  data,
  actionAPI,
  setActionAPI,
  vendorData,
  setVendorData,
  setQuoteData,
  type='',
  uploadedFromList,
  setUploadedFromList
}) {
  const [text, setText] = useState("");
  const [files, setFiles] = useState([]);
  const [items, setItems] = useState(item?.items.map((i) => ({ ...i })));
  const [removedItems, setRemovedItems] = useState([]);
  const [submittedItems, setSubmittedItems] = useState([]);
  const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [popoverGrant, setPopoverGrant] = useState(false);
  const [validToken, setValidToken] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [attachmentID, setAttachmentID] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [fileCounts, setFileCounts] = useState({ all: 0, sent: 0, received: 0 });
  const [itemsStatus, setItemsStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [itemRef, setItemRef] = useState(null);
  const [deleteItemModal, setDeleteItemModal] = useState(false);
  const [updateClicked, setUpdateClicked] = useState(false);
  const [negotiateClicked, setNegotiateClicked] = useState(false);
  const [shouldMoveToRemovedItems, setShouldMoveToRemovedItems] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);

  const [notes, setNotes] = useState([]);
  useEffect(() => {
    getComments(item?._id || data[0]?._id)
      .then((data) => setNotes(data))
      .catch((error) => console.error("Error:", error));
  }, []);

  useEffect(() => {
    getStatus(item?._id)
      .then((data) => setItemsStatus(data))
      .catch((error) => console.error("Error:", error));
  }, []);

  const handleFiles = () => {
    getFiles(`/quotations/file/${type === 'ListApprovals' ? data[0]?._id : item?._id}`)
      .then((data) => {
        setFiles(data);
      })

      .catch((error) => console.error("Error:", error));
  };

  useEffect(() => {
    handleFiles();
  }, []);

  const addNote = () => {
    const newNote = {
      text,
      timestamp: new Date(),
    };
    postComment(item?._id || data[0]?._id , newNote)
      .then((data) => {
        setNotes((notes) => [...notes, data]);
        setText("");
        fetchQuotations()
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const fetchQuotations = async () => {
    const queryString = `/quotations/quote/${item.quoteId}`;
    const data = await fetchData(queryString);
    setVendorData(data.data);
    setQuoteData(data.quoteDetails);
};

  const uploadProps = {
    name: "file",
    action: `${BASE_URL}/quotations/file/${item?._id || data[0]?._id}`,
    method: "POST",
    withCredentials: true,
    headers: {},
    fileList,
    onChange: (info) => {
      let fileList = [...info.fileList];
      setFileList(fileList);
      if (info.file.status === "done") {
        const newFile = {
          actionValue: info.file.name,
        };
        setFiles([...files, newFile]);
        //clear the uplodad file from input
        setFileList([]);
        showSuccessMessage("File uploaded successfully");
        handleFiles();
      } else if (info.file.status === "error") {
        showErrorMessage("File upload failed");
      }
    }
  };
  const handleDelete = (file) => {
    setAttachmentID(file._id);
    setDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };
  const handleDeleteAttachment = (id) => {
    const response =  fetch(`${BASE_URL}/activities/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    }).then(response => {
      if (response.status === 200) {
        const newFiles = files.filter((f) => f._id !== id);
        setFiles(newFiles);
        showSuccessMessage("File deleted successfully");
        setDeleteModal(false);
      } else {
        showErrorMessage("File deletion failed");
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
   
  };

  const saveAllItems = (combinedItems) => {
    const newItems = (Array.isArray(combinedItems) ? combinedItems : items).map(item => {
      let status = item.itemStatus;
      status = ['pending', 'approved'].includes(item.itemStatus) ? item.itemStatus : 'accepted'
      if(!item.availableQuantity && !item.supplyDate && !item.quotedPrice) {
        status = '';
      }
      if(item.negotiatedPrice && negotiateClicked) {
        status = 'negotiated';
      }
      return {
      ...item,
      submitted: status == ''? false: true,
      itemStatus: status,
      name: item.name,
      availableQuantity: parseInt(item.availableQuantity),
      negotiatedPrice: parseInt(item.negotiatedPrice),
      supplyDate: item.supplyDate,
      quotedPrice: parseInt(item.quotedPrice)
    }});
    const postItemsResult = postItems(item._id, newItems);
  
    postItemsResult
      .then((data) => {
        if (!data.ok) {
          showErrorMessage("Something went wrong");
        } else {
          setSubmittedItems(newItems);
          showSuccessMessage("Saved");
          setIsSaving(true);
          setUpdateClicked(false)
          setNegotiateClicked(false)
          fetchQuotations();
          setUploadedFromList(false);
          getStatus(item?._id)
          .then((data) => {
            setItemsStatus(data)
            setItems(data.items)
            setRemovedItems([]);
            setShouldMoveToRemovedItems(true);
            // moveToRemovedItems()
        })
        }
      })
      .catch((error) => {
        showErrorMessage("Something went wrong");
        console.error("Error:", error);
      });
  };

  const moveToRemovedItems = () => {
    const itemsToMove = items?.filter(item => {
      return item.itemStatus == '';
    });
    setRemovedItems(prevRemovedItems => {
  
      const updatedItemsToMove = itemsToMove.map(item => ({
        ...item,
        submitted: false,
        availableQuantity: null,
        supplyDate: null,
        quotedPrice: null,
        isRemoved: true
      }));
  
      return [...prevRemovedItems, ...updatedItemsToMove];
    });
  
    const updatedItems = items?.filter(item => {
      return item.itemStatus != ""
    }).sort((a, b) => a.itemRef - b.itemRef);
    setItems(updatedItems);
  };
  
  useEffect(() => {
    if (type !=='ListApprovals' && !uploadedFromList ) {
     moveToRemovedItems();
    }
  }, []);
  useEffect(() => {
    if (shouldMoveToRemovedItems) {
      moveToRemovedItems();
      setShouldMoveToRemovedItems(false);
    }
  }, [shouldMoveToRemovedItems]);

  

  const openPopupForAuthorization = () => {
    //TODO: Fix the popup close with success failure - screen and cross origin
    const popup = window.open(`${BASE_URL}/uploads/approve-drive`, "popup", "popup=true,width=500,height=650");
    const checkPopup = setInterval(() => {
        if (popup.window.location.href.includes(`${window.location.origin}/success`)) { popup.close(); setValidToken(true); }
        if (!popup || !popup.closed) return;
        clearInterval(checkPopup);
    }, 1000 * 20);
  }
    const fetchItems = async () => {
      const data = await fetchData('/uploads/valid-token')
      setValidToken(data.success)
    };

    useEffect(() => {
      fetchItems();
    }, []);


  const handleInputChange = (index, field, type) => (event) => {
    switch (type) {
      case 'item': 
              const newItems = [...items];
              newItems[index][field] = event.target.value;
              setItems(newItems);
          break;
      case 'removeItem':
              const newRemovedItems = [...removedItems];
              newRemovedItems[index][field] = event.target.value;
              setRemovedItems(newRemovedItems);
          break;
    }
};

  const handleDateChange = (index, field, type) => (date) => {
    switch (type) {
    case 'item': 
            const newItems = [...items];
            newItems[index][field] = date;
            setItems(newItems);
        break;
    case 'removeItem':
            const newRemovedItems = [...removedItems];
            newRemovedItems[index][field] = date;
            setRemovedItems(newRemovedItems);
        break;
  }
  };

  const handleUpload = async (file) => {
    const dataForId = type === 'ListApprovals' ? {_id: data[0]?._id }: {_id: item?._id}
    setUploadingFile(true);
    const { success, errorMessage, csvData } = await handleFileUpload(file, dataForId);
    if (success) {
      const parsedCSVData = parseToListObj(csvData);
      const updatedItems = item.items?.map((item, index) => {
        const csvItem = parsedCSVData[index];
        if (csvItem ) {
          return {
            ...item,
            availableQuantity: csvItem["Available Quantity"],
            dueDate: csvItem["Due Date"],
            placeOfSupply: csvItem["Place of Supply"],
            quantity: csvItem["Quantity"],
            quotedPrice: csvItem["Quoted Price"],
            supplyDate: csvItem["Supply Date"],
            maxBudget: csvItem["Max Budget"],
            minBudget: csvItem["Min Budget"],
          };
        }
        return null;
      })
  
      setItems(updatedItems);
      setRemovedItems([]);
      setIsSaving(false);
      setUploadingFile(false);
      setUpdateClicked(true);
    } else {
      showErrorMessage(errorMessage);
    }
  };
  const handleRemove = (itemRef, type) => {
    const updatedItems = items.filter((n) => n.itemRef != itemRef);
    const updatedItemsWithStatus = updatedItems.map((item, i) => {
      return {
        ...item,
        itemStatus: item.itemStatus === 'pending' || item.itemStatus === 'approved'  ? item.itemStatus : 'accepted',
        submitted: true
      };
  });
    const removedItem = items.find((n) => n.itemRef === itemRef);
    const updatedRemovedItem = {
      ...removedItem,
      submitted: false,
      availableQuantity: null,
      supplyDate: null,
      quotedPrice: null,
      itemStatus: 'remove',
    };
  
    if(type === 'remove') {
      setRemovedItems(prevRemovedItems => [...prevRemovedItems, updatedRemovedItem]);
    } else {
      setRemovedItems([...removedItems, { ...removedItem, isRemoved: false }]);
    }

    const combinedItems = [...updatedItemsWithStatus, ...removedItems, updatedRemovedItem];
    setItems(updatedItemsWithStatus);
    if (type === 'remove') {
      postItems(item._id, combinedItems)
        .then((data) => {
          if (data.ok) {
            fetchQuotations();
            getStatus(item?._id)
            .then((data) => setItemsStatus(data))
            showSuccessMessage("Item removed successfully");
          } else {
            showErrorMessage("Failed to remove item");
          }
        })
        .catch((error) => {
          showErrorMessage("Failed to remove item");
          console.error("Error:", error);
        });
    }
  };
  
  const handleDeleteItem = (itemRef) => {
    setDeleteItemModal(false);
    handleRemove(itemRef, 'remove');
  };
  const handleDeleteForItem = (itemRef) => {
    setItemRef(itemRef);
    setDeleteItemModal(true);
  };
  const closeDeleteItemModal = () => {
    setDeleteItemModal(false);
  };
  const handleRestore = (index) => {
    const newItems = [...items, ...removedItems.map(item => ({ ...item, submitted: true, itemStatus: 'accepted'}))];
    saveAllItems(newItems, index); 
   
  };

  const handleRequestApproval = async(id) => {
    if (_.isEmpty(id)) return;
    const response = await postData(`/quotations/updatestatus?id=${id}&approvalId=asdf&status=approval`, {});
    if(response) {
      showSuccessMessage('Requested Successfully')
      fetchQuotations();
      onClose();
    } else {
      showErrorMessage('Something went wrong')
    }
  }

    useEffect(() => {
    setSubmittedItems(items?.filter((item) => item.submitted))
    }, [isVisible])

    const openHistoryModal = () => {
      setIsHistoryModalVisible(true)
    }

    const closeHistoryModal = () => {
      setIsHistoryModalVisible(false)
    }

    const openRejectModal = (action) => {
      setIsRejectModalVisible(true)
      setActionAPI(action)
    }

    const closeRejectModal = () => {
      setIsRejectModalVisible(false)
    }
    const handleItemStatus = async(item, actionAPI, status) => {
        const requestedData = {
          items: [{
                  _id: item._id,
                  itemStatus: status
              }]
          };
          const data = await postData(actionAPI.actions[1].apiEndpoint, requestedData);
          if (data) {
              const messageContent = status === 'approved' ? 'Approved' : 'Rejected';
              showSuccessMessage(messageContent);
          } else {
              showErrorMessage('Something went wrong');
          }
    }

    const handleAction = async (actionAPI, type, reason = '') => {
      const action = type === 'approve' ? actionAPI.actions[0] : actionAPI.actions[2];
      let requestedData = {};
      if (type === 'reject') {
          requestedData = {
              reason: reason,
          };
      }
      const localdata = await postData(action.apiEndpoint, requestedData);
      if (localdata) {
          const messageContent = type === 'approve' ? 'Approved' : 'Rejected';
          showSuccessMessage(messageContent);
  
          // Update vendorData based on entityId
          const updatedVendorData = vendorData.map(item => {
              if (item.entityId === localdata._id) {
                  return {
                      ...item,
                      finalStatus: localdata.status
                  };
              }
              return item;
          });
  
          setVendorData(updatedVendorData);
          onClose();
      } else {
          showErrorMessage('Something went wrong');
      }
  };
  
    const handleSendReminder = async(id) => {
      if (_.isEmpty(id)) return;

      const response = await postData(`/quotations/updatestatus?status=reminder&id=${id}`, {});
      if(response) {
        showSuccessMessage('Requested Successfully')
        onClose();
      } else {
        showErrorMessage('Something went wrong')
      }
    }

    const renderActionButtons = () => {
      if (vendorData[0].allowedActions.includes('send') || vendorData[0].allowedActions.includes('send for approval')) {
        return (
          <Button type="primary" onClick={() => handleRequestApproval(item._id)}>
            <ToTopOutlined /> Request Approval
          </Button>
        );
      }
  
      if (vendorData[0].allowedActions.includes('send reminder')) {
        return (
          <Button type="primary" style={{background: 'transparent', border: '1px solid transparent', boxShadow: 'none', color: 'rgba(0, 0, 0, 0.25)'}}
                  onClick={() => handleSendReminder(item._id)}>
            Send Reminder
          </Button>
        );
      }
  
      if (vendorData[0].allowedActions.includes('send PO')) {
        return (
          <Button type="primary">
            Send PO
          </Button>
        );
      }
  
      return null;
    };
    useEffect(() =>{
      if(uploadedFromList){
        setUpdateClicked(true)
      }
      if(type !=='ListApprovals'){
        renderActionButtons();
      }
    });
  


  return (
   
    <>
    <Modal
      open={isVisible}
      onCancel={onClose}
      title={'Quotation'}
      footer={null}
      className="Quotes_modal Modal_qt"
    >
      <div className="Quotes_container">
        <div className="Quotation_firstContainer">
          <div className="Quotation_subContainer">
            <span>
              Dated: <span>{dayjs(item?.createdAt).format("DD/MM/YYYY")}</span>
            </span>
            <span>
              {type === 'ListApprovals' ? 
                <>
                  From: <span>{actionAPI?.userMeta.name}</span>
                </>
              :
                <>
                  Vendor: <span>{item?.vendorDocs[0]?.name}</span>
                </>
              }
              
            </span>
          </div>
          <div className="Quotation_subContainer">
            <span>
              Quote Id:{' '} 
              <span>
                {quoteData?.quoteId}
              </span>
            </span>
            {type === 'ListApprovals' ? 
            <span className={`Approvals_ilink ${item.status === 'completed' || item.status === 'approved' ? 'disabled' : ''}`}>
              Submitted quote <SelectOutlined />
            </span>
          : 
            (item.quoteFile && item.quoteFile.length >0 ? (
              <span className={`Quotation_upload Approvals_ilink ${item.status === 'completed' || item.status === 'approved' ? 'disabled' : ''}`} style={{ cursor: 'pointer', color: '#4800FF' }}>
                <a href={item.quoteFile[0]?.meta?.durl_link} target="_blank" rel="noreferrer" style={{color: 'inherit', pointerEvents: item.status === 'completed' || item.status === 'approved' ? 'none' : 'auto', filter: item.status === 'completed' || item.status === 'approved' ? 'blur(2px)' : 'none'}} >
                  Submitted Quote <SelectOutlined style={{ cursor: 'pointer', color: '#4800FF' }} />
                </a>
              </span>
            ) : 
            <Upload
              showUploadList={false}
              accept=".csv"
              beforeUpload={handleUpload}
              disabled={item.status === 'completed' || item.status === 'approved'}
            >
              <span className={`Quotation_upload ${item.status === 'completed' || item.status === 'approved' ? 'disabled' : ''}`} style={{ cursor: 'pointer', color: '#4800FF', filter: item.status === 'completed' || item.status === 'approved' ? 'blur(2px)' : 'none' }}>
                Upload Quote {' '} 
                <UploadOutlined style={{ cursor: 'pointer', color: '#4800FF' }} />
              </span>
            </Upload>
            )
          }
          </div>
        </div>
        {quoteData && (
        <TableComponent 
          quoteData={quoteData}
          openHistoryModal={openHistoryModal}
          itemsStatus={itemsStatus}
          type={'viewData'}
          type2={type}
         />
        )}

      {type != 'ListApprovals' &&
        <TableComponent 
          items={items}
          handleRemove={handleRemove}
          handleDeleteForItem={handleDeleteForItem}
          submittedItems={submittedItems}
          handleInputChange={handleInputChange} 
          handleDateChange={handleDateChange}
          setIsSaving={setIsSaving}
          saveAllItems={saveAllItems}
          itemsStatus={itemsStatus}
          isSaving={isSaving}
          updateClicked={updateClicked}
          negotiateClicked={negotiateClicked}
          setUpdateClicked={setUpdateClicked}
          setNegotiateClicked={setNegotiateClicked}
          removedItems={removedItems}
          handleRestore={handleRestore}
          type={'editData'}
          type2={type}
        />
        }
         {type === 'ListApprovals' &&
          <TableComponent 
          items={data[0]?.items}
          actionAPI={actionAPI}
          openHistoryModal={openHistoryModal}
          type={'viewData'}
          type2={type}
          handleItemStatus={handleItemStatus}
         />
        }
        <QuoteAttachment 
          files={files}
          handleDelete={handleDelete}
          popoverGrant={popoverGrant} 
          validToken={validToken} 
          openPopupForAuthorization={openPopupForAuthorization}
          setPopoverGrant={setPopoverGrant}
          handleFiles={handleFiles}
          fileCounts={fileCounts}
          type2={type}
          />

          <QuoteNotes
            notes={notes}
            text={text}
            setText={setText}
            uploadProps={uploadProps}
            addNote={addNote}
            type2={type}
            />
      </div>
      <div className="Quotes_footer">
      {type === 'ListApprovals' ? (
        <div className="Approval_footer">
          <Button onClick={() => handleAction(actionAPI, 'approve')}>Approve All</Button>
          <Button onClick={() => openRejectModal(actionAPI)}>Reject All</Button>
        </div>
      ) : (
        renderActionButtons()
      )}
      </div>
      {isLoading && showLoader('Requesting')}
      {uploadingFile && showLoader('Processing CSV...')}
    </Modal>

    <ModalHistoryComponent
      isVisible={isHistoryModalVisible}
      onClose={closeHistoryModal}
      AvatarColor={AvatarColor}
      name={quoteData?.title}
      id={item?._id}
      />
    {deleteModal && <RequestNudgeComponent
        type={'deleteAttachment'}
        data={attachmentID}
        isVisible={deleteModal}
        onClose={closeDeleteModal}
        handleNudgeSubmit={handleDeleteAttachment}
      />}
    {deleteItemModal &&
    <RequestNudgeComponent
        type={'removeItem'}
        data={itemRef}
        isVisible={deleteItemModal}
        onClose={closeDeleteItemModal}
        handleNudgeSubmit={handleDeleteItem}
      />
    }
    <ModalRejectComponent
      isVisible={isRejectModalVisible}
      onClose={closeRejectModal}
      handleAction={handleAction}
      action={actionAPI}
      type={'reject'}
      />
    </>
  );
}
