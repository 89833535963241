import React, { useEffect, useRef, useState } from 'react';
import { Layout } from 'antd';

import Cookies from 'js-cookie';
import { useSelector, useDispatch } from "react-redux";

import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import "./style.css";
import VendorOnboard from '../../vendor/vendor-add/index';
import SiderComponent from '../../common/SiderComponent';
import { COMPONENT_ROUTE_MAP } from '../../../constants/index';
import VendorLists from '../../vendor/vendor-list';
import VendorProfile from '../../vendor/vendor-profile';
import VendorTasks from '../../vendor/vendor-tasks';
import VendorRank from '../../feature/VendorRank';
import VendorDiscover from '../../feature/VendorDiscover';
import AccountInfo from '../../account';
import EnterpriseData from '../../enterprise-data';
import DashboardOverall from '../../dashboard/dashboard-overall';
import VendorSubscriptionPlans  from '../../vendor/vendor-subscription';
import { clearUser } from '../../../helpers/userSlice';
import { setUser } from "../../../helpers/userSlice";
import { fetchData } from '../../../services/HomeService';

import { showErrorMessage } from '../../../utils';
import VendorQuotations from '../../vendor/vendor-quotations';
import VendorListQuotes from '../../vendor/vendor-quotations/VendorListQuotes';
import VendorApprovals from '../../vendor/vendor-approval';
import VendorExplore from '../../vendor/vendor-explore';

const { Content } = Layout;

const Sidebar = ({ items, getIcon, orgType,  verified, step, AvatarColor, ComponentConfig}) => {
    const { type, subtype } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user); 

    const [collapsed, setCollapsed] = useState(false);
    const [selectedParent, setSelectedParent] = useState('');
    const [selectedChild, setSelectedChild] = useState('');
    const intervalDurationRef = useRef(20 * 1000); 

    const sessionStatus = async () => {
      const resp = await fetchData('/users/session/status');
      if (!resp.success) {
        showErrorMessage('Invalid session- Try Loggin again!!')
        await logout();
      } else {
        //TODO:  Update here to skip login step
        console.log('Session Check Sucessfull!!')
      }
    }
    
    const logout = async () => {
      const resp = await fetchData('/users/auth/logout')
      if(resp.success) {
          dispatch(clearUser());
          navigate('/login');
      }
    };

    useEffect(() => {
      document.addEventListener('visibilitychange', function () {
        if (!document.hidden) {
          // Tab Active: Restart the status check calls
          intervalDurationRef.current = 20 * 1000;
        } else{
          // Tab Inactive: Stop status check calls
          intervalDurationRef.current = 0;
        }
      });
    }, []);
    
    useEffect(() => {
      let intervalId;
      if (intervalDurationRef.current) {
        intervalId = setInterval(() => {
          console.log('Session-Call-', intervalDurationRef.current);
          sessionStatus();
          intervalDurationRef.current = intervalDurationRef.current + 1 * 60 * 1000;
        }, intervalDurationRef.current);
      }

      return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const userCookie = Cookies.get("userInfo");
        if (userCookie) {
            console.log('Setting user with cookies and state')
            const userInfo = JSON.parse(userCookie);
            dispatch(setUser({...userInfo, ...userData}));
        }
        if(!type && !(orgType && (verified === 'true'))){
            if (userCookie) {
                console.log('Setting user with cookies')
                const userInfo = JSON.parse(userCookie);
                dispatch(setUser({...userInfo}));
            }
            navigate(`/login?type=selection&org_type=${orgType}&verified=${verified}&step=${step}`);
        }else if (!type || !_.values(COMPONENT_ROUTE_MAP).includes(type)) {
            navigate(`/home/${COMPONENT_ROUTE_MAP['DashboardOverall']}`);
        }
    }, [type, navigate]);

    const handleCollapse = (collapsed) => {
        setCollapsed(collapsed);
    };

    const handleBreadCrumb = (child, parent) => {
        setSelectedChild(child);
        setSelectedParent(parent);

    };

    console.log('Sidebar component rendered');

    return (
    <>
        <Layout style={{ minHeight: '100vh', position: "absolute", zIndex: "-1", boxShadow: " 0px 2px 8px rgba(0, 0, 0, 0.15)" }}>
            <SiderComponent items={items} getIcon={getIcon} handleCollapse={handleCollapse} collapsed={collapsed} onMenuItemSelect={handleBreadCrumb} />
            <Layout>
                <Content style={{ margin: '4px', backgroundColor: '#F5F5F5',zIndex: '99', height: '100%', paddingBottom: '86px'}}>
                    {type === COMPONENT_ROUTE_MAP['DashboardOverall'] && <DashboardOverall selectedParent={selectedParent} selectedChild={selectedChild} AvatarColor={AvatarColor} ComponentConfig={ComponentConfig}/>}  
                    {type === COMPONENT_ROUTE_MAP['VendorOnboard'] && <VendorOnboard selectedParent={selectedParent} selectedChild={selectedChild} />}
                    {type === COMPONENT_ROUTE_MAP['VendorLists'] && <VendorLists selectedParent={selectedParent} selectedChild={selectedChild} AvatarColor={AvatarColor} />}
                    {type === COMPONENT_ROUTE_MAP['VendorProfile'] && <VendorProfile selectedParent={selectedParent} selectedChild={selectedChild} AvatarColor={AvatarColor} />}
                    {type === COMPONENT_ROUTE_MAP['VendorTasks'] && <VendorTasks selectedParent={selectedParent} selectedChild={selectedChild} AvatarColor={AvatarColor} />}
                    {type === COMPONENT_ROUTE_MAP['EnterpriseData'] && <EnterpriseData selectedParent={selectedParent} selectedChild={selectedChild} />}
                    {type === COMPONENT_ROUTE_MAP['VendorRank'] && <VendorRank selectedParent={selectedParent} selectedChild={selectedChild} />}
                    {type === COMPONENT_ROUTE_MAP['VendorDiscover'] && <VendorDiscover selectedParent={selectedParent} selectedChild={selectedChild} />}
                    {type === COMPONENT_ROUTE_MAP['VendorExplore'] && <VendorExplore selectedParent={selectedParent} selectedChild={selectedChild} />}
                    {type === COMPONENT_ROUTE_MAP['AccountInfo'] && <AccountInfo selectedParent={selectedParent} selectedChild={selectedChild} AvatarColor={AvatarColor} />}
                    {type === COMPONENT_ROUTE_MAP['VendorSubscriptionPlans'] && <VendorSubscriptionPlans  selectedParent={selectedParent} selectedChild={selectedChild} />}
                    {type === COMPONENT_ROUTE_MAP['VendorQuotations'] && subtype !== COMPONENT_ROUTE_MAP['Vendors'] && <VendorQuotations selectedParent={selectedParent} selectedChild={selectedChild} AvatarColor={AvatarColor} ComponentConfig={ComponentConfig} />}
                    {type === 'quotations' && subtype === 'vendors' && <VendorListQuotes selectedParent={selectedParent} selectedChild={selectedChild} AvatarColor={AvatarColor} ComponentConfig={ComponentConfig} />}
                    {type === COMPONENT_ROUTE_MAP['VendorApprovals'] && <VendorApprovals selectedParent={selectedParent} selectedChild={selectedChild} AvatarColor={AvatarColor} ComponentConfig={ComponentConfig} />}
                </Content>
            </Layout>
        </Layout>
    </>
    );
};

export default Sidebar;
