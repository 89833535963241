import React, { useState, useEffect } from 'react';
import BreadcrumbComponent from '../common/BreadcrumbComponent';
import { Alert, Avatar, Button, message } from 'antd';
import './style.css';
import FormRegistration from '../common/FormRegistration';
import { insertApiCall, formTemplate, updateApiCall } from '../../services/RegistrationService';
import { fetchData } from '../../services/HomeService';
import _ from 'lodash';
import { ACCOUNT_CTA_MAP, COPY_MESSAGE } from '../../constants';

import { useSelector, useDispatch } from "react-redux";
import { updateUser } from '../../helpers/userSlice';
import { checkEmptyFields, checkRegexInValidation, showErrorMessage } from '../../utils';
import { CloseOutlined, ExclamationCircleFilled } from '@ant-design/icons';

const SOURCE_ENTITY = 'users';

export default function AccountInfo({ selectedParent, selectedChild, AvatarColor }) {
    const [formTemplateData, setFormTemplateData] = useState({});
    const [formPrefillData, setFormPrefillData] = useState(null);
    const [showAlertNudge, setShowAlertNudge] = useState(true);
    const [disabledFields, setDisabledFields] = useState({});
    const [disabledSections, setDisabledSections] = useState({});
    const [isFormDataValidated, setIsFormDataValidated]= useState(false);

    const userData = useSelector((state) => state.user); 
    const dispatch = useDispatch();

    const [orgId, setOrgId] = useState(_.get(userData, 'org', ''));

    useEffect(() => {
        fetchTemplate();
    }, []);
    const fetchItems = async (pid) => {
        const data = pid? await fetchData(`/partners/${pid}`): null;
        if (data && !_.isEmpty(data)) {
            setFormPrefillData(data);
            updateDependencies(data);
        }    
    };
    const updateDependencies = (data) => {
        const disabledFields = {};
        const disabledSections = {};
        // Correctly flatten the sections into a single array
        const flatData = []
        Object.keys(data.sections).forEach(sectionKey => {
            const section = data.sections[sectionKey];
            flatData.push(...section);
        });
        // Iterate over the flattened array of fields
        flatData.forEach(fieldData => {
            const isFieldDisabled = fieldData.fieldStyle?.disabled;
            const isFieldHidden = fieldData.fieldStyle?.hide;
            if (isFieldDisabled) {
                disabledFields[fieldData.fieldKey] = isFieldDisabled;
            }
            if (isFieldHidden) {
                disabledSections[fieldData.fieldKey] = isFieldHidden;
            }
        });
        setDisabledFields(disabledFields);
        setDisabledSections(disabledSections);
    }

    useEffect(() => {
        if (orgId) {
            fetchItems(orgId);
        }
    }, [orgId]);

    const makeApiCallAndSetStatus = async (requestData, source_entity, pid='') => {
        const { business_brand_name } = requestData;
        try {
            const apiCallFunction = formPrefillData ? updateApiCall : insertApiCall;
            const { success, id, message:msg='' } = await apiCallFunction({requestData, source_entity, pid, registerType: 'account'});
            if (success) {
                message.success(msg || 'Information updated successfully!');
                if(!orgId && id) {
                    setOrgId(id);
                    dispatch(updateUser({org: id}))
                }
                if (business_brand_name) {
                    dispatch(updateUser({ orgName: business_brand_name }));
                }
            } else {
                message.error(msg || 'Failed to update information');
                if(orgId) fetchItems(orgId)
            }
        } catch (error) {
            message.error('An error occurred while updating information.');
        }
    };
    
    const handleUpdateInformation = async () => {
       
        if (checkEmptyFields(formTemplateData, disabledFields, disabledSections)) {
            setIsFormDataValidated(true); 
            showErrorMessage('Some required fields are missing');
            return;
        } else {
            setIsFormDataValidated(false);
        }
  
        if (checkRegexInValidation(formTemplateData, disabledFields, disabledSections)) {
            setIsFormDataValidated(true);
            showErrorMessage('Some fields have invalid inputs')
            return
        } else {
            setIsFormDataValidated(false);
        }

        const requestPayload = await formTemplate(_.mapValues(formTemplateData, 'fieldValue'), SOURCE_ENTITY, 'Account Name', '');
        await makeApiCallAndSetStatus(requestPayload, SOURCE_ENTITY, formPrefillData ? orgId: '');
    };
    
    const fetchTemplate = () => {
        fetchData('/partners/register/account')
            .then((resp) => {
                setFormTemplateData(resp.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleDisabledFields = (index, fields) => {
        setDisabledFields({ ...disabledFields, ...fields })
    };
    const handleDisabledSections = (index, section) => {
        setDisabledSections({ ...disabledSections, ...section })

    };

    const handleInputFieldChange = async (index, formData) => {
        setFormTemplateData(formData);
    };

    const handleCopyInfo = () => {
        const copiedText = _.map(formTemplateData, ({ fieldLabel, fieldValue }) => `${fieldLabel} : ${fieldValue}`).join('\n');
        navigator.clipboard.writeText(copiedText);
        message.success(COPY_MESSAGE, 1.5);
    };


    return (
        <section className='Home_container'>
            <section className="Home_firstContainer">
                <BreadcrumbComponent selectedParent={selectedParent} selectedChild={selectedChild} />
                <div className="Home_heading">
                    <h1>Account Information</h1>
                </div>
            </section>
            {/* TODO: Add check to showc this only until the first update by the vendor */}
            {userData?.orgType === 'vendor' && formPrefillData && showAlertNudge && (
                <section className='account_container-2'>
                    <div className="ProfileView_alertContainer">
                        <Alert
                            message={
                                    <div className="ProfileView_alertIcon">
                                        <ExclamationCircleFilled style={{ color: '#FAAD14' }} /> {ACCOUNT_CTA_MAP['ACCOUNT_VERIFICATION']}
                                    </div>
                            }
                            type={'error'}
                            description=<CloseOutlined  onClick={() => setShowAlertNudge(false)}/>
                        />
                    </div>
                </section> 
            )}   
            <main className='VendorOnboard_adding-1'>
                <section className='Registration_firstBox-1'>
                    <div className='ProfileView_photo'>
                        {/* TODO: Too complex checks- smoothen all checks */}
                        <Avatar style={!userData?.picture ? AvatarColor?.[0]?.style : {}}  src={userData?.picture}>
                            {!userData?.picture  && (userData?.name ? userData.name?.charAt(0).toUpperCase() : 'U')}
                        </Avatar>
                    </div>
                    <div className='ProfileView_introduction'>
                        <h2>{userData ? userData?.name : "User"}</h2>
                        <p>
                            {userData ? userData?.email : 'email'} <span style={{ color: '#D9D9D9' }}> &nbsp; &#8226; &nbsp;</span> mobile number
                        </p>
                    </div>
                    <div className='ProfileView_topButton'>
                    <Button style={userData?.orgType === 'vendor' ? { background: '#008065', border: '1px solid #008065' } : {}} onClick={handleCopyInfo}>Copy</Button>
                    </div>
                </section>
                <FormRegistration  
                    disabledFields={disabledFields}
                    disabledSections={disabledSections} 
                    setDisabledFields={handleDisabledFields} 
                    setDisabledSections={handleDisabledSections} 
                    formIndex={0} formTemplateData={formTemplateData} 
                    onFieldChange={handleInputFieldChange} 
                    formPrefillData={formPrefillData} 
                    formDataValidation={isFormDataValidated} 
                    formStyle={{ padding: '24px' }} 
                    formFormatLong={true} partnerID={orgId}
                    fetchItems={fetchItems} 
                />
            </main>
            <div className='EnterpriseInfo_Button'>
            <Button style={userData?.orgType === 'vendor' ? { background: '#008065', border: '1px solid #008065' } : {}} onClick={handleUpdateInformation}>Update Information</Button>
            </div>
        </section>
    );
}
