import React, { useState, useEffect } from 'react';
import { Modal, Button, Select, Checkbox, message } from 'antd';
import { postData } from '../../../services/HomeService';
import { downloadCSVFile } from '../../../utils';
import './style.css';

const ExportModalComponent = ({ isVisible, onClose, vendorSelections, overallData={}, vendorData, user , singleId}) => {
  const [projection, setProjection] = useState({form: [], attachment: []});
  const [selectedKeys, setSelectedKeys] = useState({"form": {}, "attachment": {}});

  useEffect(() => {
    if (!isVisible) {
      setSelectedKeys({"form": {}, "attachment": {}});
      setProjection({form: [], attachment: []});
    }
  }, [isVisible]);

  const handleMenuClick = (outerKey, section, label, e) => {
    e.stopPropagation();

    setSelectedKeys(prevSelectedKeys => {
      const newSelectedKeys = { ...prevSelectedKeys };
      const newProjection = { ...projection };

      if (!newSelectedKeys[outerKey]) {
        newSelectedKeys[outerKey] = {};
      }

      if (!newSelectedKeys[outerKey][section]) {
        newSelectedKeys[outerKey][section] = [];
      }

      if (newSelectedKeys[outerKey][section].includes(label)) {
        newSelectedKeys[outerKey][section] = newSelectedKeys[outerKey][section].filter(item => item !== label);
        newProjection[outerKey] = newProjection[outerKey].filter(item => item !== label);
      } else {
        newSelectedKeys[outerKey][section].push(label);
        if (!newProjection[outerKey]) {
          newProjection[outerKey] = [];
        }
        newProjection[outerKey].push(label);
      }

      setProjection(newProjection);
      return newSelectedKeys;
    });
  };

  const handleExport = () => {
    const selectedIds =  Object.keys(vendorSelections) && Object.keys(vendorSelections).length > 0? Object.keys(vendorSelections) : singleId ? [singleId] : [];
    const selectedPids = vendorData.filter(vendor => selectedIds.includes(vendor._id)).map(vendor => vendor.sinkPartnerId);
    const queryString = `/partners/getall/${user.org}`;
    postData(queryString, { ids: selectedPids, projections: projection })
      .then((response) => {
        if (response.success) {
          const fileName = 'vendor_export.csv';
          const success = downloadCSVFile(response.data, fileName);
          if (success) {
            onClose();
            message.success('Exported successfully');
          }
        } else {
          message.error('Export failed');
        }
      })
      .catch((error) => {
        console.error('Export error:', error);
        message.error('Export failed');
      });
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    return (
      <span style={{ height: "40px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{value}</span>
    );
  };

  return (
    <Modal
      open={isVisible}
      onCancel={onClose}
      title="Select keys to be Exported"
      footer={<Button key="done" type="primary" style={{background: "#4800FF", marginRight: "20px"}} onClick={handleExport}>Export</Button>}
      className='ExportModal_container'
      style={{ maxWidth: '500px' }}
    >
      <div className='ExportModal_subContainer' style={{ display: "flex", height: "400px", overflow: "hidden", overflowY: "scroll", flexDirection: "column", marginTop: "20px" }}>
        {Object.keys(overallData).map(outerKey => (
          <div key={outerKey == 'form'? "Form": "Attachments"}>
            <h4 style={{ textAlign: "left", margin: "0px", padding: "0px", fontSize: "16px", textAlign: "left", fontWeight: "bold" }}>{outerKey == 'form'? "Form": "Attachments"}</h4>
            {Object.keys(overallData[outerKey]).map(section => {
              const selectedItems = selectedKeys[outerKey][section] || [];
              let displayValue = selectedItems.join(', ');
              if (displayValue.length > 35) {
                displayValue = `${displayValue.slice(0, 35)}...`;
              }
              return (
                <div key={section} style={{ display: 'flex', flexDirection: "row" }}>
                  <p style={{ width: "150px", margin: "0px", textAlign: "left" }}>{section} :</p>
                  <Select
                    mode="multiple"
                    value={displayValue}
                    removeIcon={<></>}
                    variant='outlined'
                    style={{ width: '100%', maxWidth: '280px', height: '40px', fontSize: '14px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    tagRender={tagRender}
                  >
                    {overallData[outerKey][section].map((label, index) => (
                      <Select.Option key={label} value={label}>
                        <Checkbox
                          key={label}
                          checked={selectedKeys[outerKey][section]?.includes(label)}
                          onChange={(e) => {
                            handleMenuClick(outerKey, section, label, e);
                          }}
                        >
                          {label}
                        </Checkbox>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default ExportModalComponent;