import React, { useEffect, useState } from "react";
import QuoteAttachment from "../vendor-quotations/QuoteAttachment";
import dayjs from "dayjs";
import { Button, Descriptions, Modal } from "antd";
import {
  getCommentsApproval,
  getFiles,
  postCommentApproval,
} from "../vendor-quotations/quotationService";
import _ from 'lodash';
import RequestNudgeComponent from "../../nudge/RequestNudgeComponent";
import { capitalizeFirstLetter, getStatusStyle, showErrorMessage, showSuccessMessage, formatToCustomTime } from "../../../utils";
import { BASE_URL } from "../../../constants";
import { postData, fetchData } from "../../../services/HomeService";
import TextArea from "antd/es/input/TextArea";
import {AttachmentService} from "../../../services/AttachmentService";
import { useSelector } from 'react-redux';
import AttachFilesComponent from '../vendor-profile/AttachFilesComponent';

export default function ModalApprovalComponent({ isVisible, onClose, type, item, vendorData, setVendorData }) {
  const [text, setText] = useState("");
  const userData = useSelector((state) => state.user); 
  const getRequestType = (fieldType) => {
    switch (fieldType) {
        case 'Verification':
            return 'Request Verification';
        case 'Esign':
            return 'Request Esign';
        case 'Read':
            return 'Extract Information';
        case 'Billing':
            return 'Add to Expense';
        default:
            return '';
    }
};
  const [files, setFiles] = useState([]);
  const [attachmentID, setAttachmentID] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [attachedData, setAttachmentsData] = useState({});
  const [verificationData, setVerificationData] = useState({});
  const [isVerifyModal, setIsVerifyModal] = useState(false);
    const [profileData, setProfileData] = useState({});
    const [attachmentSections, setAttachmentSections] = useState({}); 
    const [popoverGrant, setPopoverGrant] = useState(false);
    const [validToken, setValidToken] = useState(null);

  
  const [fileCounts, setFileCounts] = useState({
    all: 0,
    sent: 0,
    received: 0,
  });
  const [notes, setNotes] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [reason, setReason] = useState('');
  const maxCharacterCount = 50;
  const partnerId = item?.entityId

  useEffect(() => {
    getCommentsApproval(item?._id)
      .then((data) => setNotes(data))
      .catch((error) => console.error("Error:", error));
  }, []);
  const {
    fetchAttachments,
    handleNewAttachment,
    uploadFileToDrive,
  } = AttachmentService(partnerId);


const _fetchItems = async () => {
    const _data = await fetchData(`/partners/${partnerId}`);
    setProfileData(_data);
};

useEffect(() => {
    if (partnerId) {
        const org = userData? userData.org : null;
        const _fetchAttachments = async (org) => {
            const _data = await fetchAttachments(org);
            setAttachmentsData(_data);   
        };

        _fetchItems();
        _fetchAttachments(org);
        // fetchNotes();
        
    }
}, [partnerId]);

  const addNote = () => {
    const newNote = {
      text,
      timestamp: new Date(),
    };
    postCommentApproval(item?._id, newNote)
      .then((data) => {
        setText("");
        return getCommentsApproval(item?._id);
      })
      .then((data) => setNotes(data))
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const uploadProps = {
    name: "file",
    action: `${BASE_URL}/approvals/files/${item?._id}`,
    method: "POST",
    withCredentials: true,
    headers: {},
    fileList,
    onChange: (info) => {
      let fileList = [...info.fileList];
      setFileList(fileList);
      if (info.file.status === "done") {
        //clear the uplodad file from input
        setFileList([]);
        showSuccessMessage("File uploaded successfully");
        handleFiles();
      } else if (info.file.status === "error") {
        showErrorMessage("File upload failed");
      }
    },
  };

  const handleFiles = () => {
    getFiles(`/approvals/files/${item?._id}?filter=all`)
      .then((data) => {
        setFiles(data);
        const allCount = data?.all?.length ?? 0;
        const sentCount = data?.sent?.length ?? 0;
        const receivedCount = data?.received?.length ?? 0;
        setFileCounts({ all: allCount, sent: sentCount, received: receivedCount });
      })

      .catch((error) => console.error("Error:", error));
  };

  useEffect((key) => {
    handleFiles(key);
  }, []);

  const handleDelete = (file) => {
    setAttachmentID(file._id);
    setDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

const handleDeleteAttachment = (id) => {
  fetch(`${BASE_URL}/activities/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  })
    .then((response) => {
      if (response.status === 200) {
        let newFiles;
        // Check if files is in the expected format
        if (files.hasOwnProperty('all') && files.hasOwnProperty('sent') && files.hasOwnProperty('received')) {
          newFiles = {
            all: files.all.filter((f) => f._id !== id),
            sent: files.sent.filter((f) => f._id !== id),
            received: files.received.filter((f) => f._id !== id),
          };
        } else if (Array.isArray(files)) { // Check if files is an array
          newFiles = files.filter((f) => f._id !== id);
        } else {
          console.error("Unexpected files format");
          // return;
        }
        setFiles(newFiles);
        showSuccessMessage("File deleted successfully");
        setDeleteModal(false);
      } else {
        showErrorMessage("File deletion failed");
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
const openDeleteModal = (_id, key) => {
  setAttachmentID({_id, key});
  setDeleteModal(true);
}
const openVerifyModal = (fieldKey, fieldValue) => {
  setVerificationData({ [fieldKey]: fieldValue });
  setIsVerifyModal(true);
};
const openUploadModal = (section, fieldLabel, fieldKey) => {
    console.log(section, fieldLabel, fieldKey);
}

  const handleAction = async (actionAPI, type, reason = '') => {
    console.log('actionAPI',type, actionAPI);
    let action ;
    const actions = actionAPI.actions || [];
    if(type == 'approve'){
      action = actions.find(action => action.name === 'Approve');
    }
    else if(type == 'reject'){
      action = actions.find(action => action.name === 'Reject');
    }
    else{
      action = actions.find(action => action.name === 'Partial Approve');
    }
    console.log('action', action);
    let requestedData = {
      items: actionAPI.items
    };
    if (type !== 'approve') {
        requestedData = {
            ...requestedData,
            reason: reason,
        };
    }
    const data = await postData(action.apiEndpoint, requestedData);
    if (data) {
        const messageContent = type === 'approve' ? 'Approved' : 'Rejected';
        showSuccessMessage(messageContent);

        // Update vendorData based on entityId
        const updatedVendorData = vendorData.map(item => {
            if (item.entityId === data._id) {
                return {
                    ...item,
                    finalStatus: data.status
                };
            }
            return item;
        });

        setVendorData(updatedVendorData);
        onClose();
    } else {
        showErrorMessage('Something went wrong');
    }
};
const vendorTheme = userData?.orgType == 'vendor' ? "#008065" : null;
  return (
    <Modal
    open={isVisible}
    onCancel={onClose}
    title={'Approval request'}
    footer={null}
    className="Quotes_modal Modal_qt"
  >
   
   <AttachFilesComponent theme={vendorTheme} attachedData={attachedData} openUploadModal={openUploadModal} openDeleteModal={openDeleteModal}  openVerifyModal={openVerifyModal} partnerId={partnerId}/>
                
        <div className='Approval_Reject' style={{padding: '0 24px 24px 24px'}}>
        <div className="Quotation_subtitle">Reason for rejection if you want to reject.</div>
            <TextArea
                rows={3}
                placeholder="Type here"
                value={reason}
                onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= maxCharacterCount) {
                        setReason(inputValue);
                    }
                }}
            />
            <div className='Approval_Reject_Mend'>
                <span><span style={{ color: '#FF4D4F' }}>*</span>This is a mandatory field</span>
                <span>{reason.length}/{maxCharacterCount}</span>
            </div>
        </div>
    <div className="Quotes_footer">
       
            <Button className="approvals_btn" onClick={() =>handleAction(item, "approve")}>
                Approve
            </Button>
        
            <Button className="reject_btn" onClick={() => handleAction(item, "reject", reason)} disabled={reason.length === 0 || reason.length > maxCharacterCount} >
                Reject
            </Button>
           <Button className="reject_btn" onClick={() => handleAction(item, "reminder", reason)} >
              Send Reminder
            </Button>
           

    </div>

    <RequestNudgeComponent
        type={'deleteAttachment'}
        data={attachmentID}
        isVisible={deleteModal}
        onClose={closeDeleteModal}
        handleNudgeSubmit={handleDeleteAttachment}
      />
  </Modal>
  );
}
