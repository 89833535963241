import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { ArrowLeftOutlined, DownloadOutlined, CheckCircleFilled, LoadingOutlined, ExclamationCircleFilled, UploadOutlined, MoreOutlined, PlusOutlined, CopyOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Avatar, Button } from 'antd';
import BreadcrumbComponent from '../../common/BreadcrumbComponent';
import { deleteData, fetchData, postData } from '../../../services/HomeService';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import './style.css';
import ModalDialogComponent from '../../common/ModalDialogComponent';
import { COMPONENT_DISPLAY_TEXT, PROFILE_CTA_MAP } from '../../../constants';
import { downloadCSVFile, formatToCustomTime, showErrorMessage, showSuccessMessage } from '../../../utils';
import { createNote, updateNote, deleteNote } from '../../../services/ActivitiesService';
import NotesListComponent from '../NotesListComponent';
import RequestNudgeComponent from '.././../nudge/RequestNudgeComponent';
import ModalUploadComponent from './ModalUploadComponent';
import AttachFilesComponent from '../vendor-profile/AttachFilesComponent';
import { AttachmentService, getAttachmentTemplate } from '../../../services/AttachmentService';
import StatusPopoverComponent from '../../common/StatusPopoverComponent';
import EditableLabel from '../../common/EditableLabel';

// TODO: Remove this hard coded values 
// Solution1- Fetch with presistent storage
const _selectedParent = 'Vendor Actions';
const _selectedChild  = 'All Added Vendors';

export default function VendorProfile({ selectedParent, selectedChild, AvatarColor  }) {
    const [profileData, setProfileData] = useState(null);
    const [modalPopup, setModalPopup] = useState(null);
    const userData = useSelector((state) => state.user); 
    
    const [notesData, setNotesData] = useState([]);
    const [activeNoteData, setActiveNoteData] = useState(null);

    const [isDailogBoxVisible, setIsDailogBoxVisible] = useState(false);
    
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const partnerId =   _.get(userData, 'org', '') //queryParams.get('id');
    // const partnerId = "66c8bf769f7b23d51b42e11e"

    //TODO: how to get this id? 
        

    // TODO: Fix the error state with UI changes
    if (partnerId === "") window.alert("Error in loading the component")

    const navigate = useNavigate();
    const [isDownloading, setDownloading] = useState(false);
    const [isDownloadingError, setDownloadingError] = useState(false);
    const [showVerificationAlert, setShowVerificationAlert] = useState(false);
    const [popoverOnboarding, setPopoverOnboarding] = useState(null);

    const [attachmentsData, setAttachmentsData] = useState({});
    const [uploadOptions, setUploadOptions] = useState([]);
    const [isVerifyModal, setIsVerifyModal] = useState(false);
    const [isUploadModal, setIsUploadModal] = useState(false);
    const [activeAttachmentData, setActiveAttachmentData] = useState({});
    const [verificationData, setVerificationData] = useState({});
    const [deleteModal, setDeleteModal] = useState(false);
    const [attachmentID, setAttachmentID]=useState(null);
    const [isEditing, setIsEditing] = useState(null); 
    const [isLoading, setIsLoading] = useState(false);

    const {
        fetchAttachments,
        handleNewAttachment,
        uploadFileToDrive,
      } = AttachmentService(partnerId);


    const _fetchItems = async () => {
        const _data = await fetchData(`/partners/${partnerId}`);
        setProfileData(_data);
    };

    useEffect(() => {
        if (partnerId) {
            const _fetchAttachments = async () => {
                const _data = await fetchAttachments();
                setAttachmentsData(_data);   
            };

            _fetchItems();
            _fetchAttachments();
            fetchNotes();
            
        }
    }, [partnerId]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleReminderClick = () => {
        // TODO : Need to implement the logic after onClick
        setShowVerificationAlert(true);
    };

    const handleModalTrippleDot = (item) => {
        if (modalPopup === item) {
            setModalPopup(null);
        } else {
            setModalPopup(item);
        }
    };
    
    const fieldValuesMap = _.mapKeys(_.flatMap(profileData?.sections), 'fieldKey');
    const additionalText = COMPONENT_DISPLAY_TEXT['PROFILE_BREADCRUMB'];

    // * ----------------------- Download and Copy Management Functions--------------------------------

    const handleCopyClick = () => {
        if (profileData?.sections) {
            const formattedData = Object.keys(profileData.sections).flatMap(sectionKey =>
                [sectionKey, ...profileData.sections[sectionKey].map(field =>
                    `${field.fieldLabel} : ${field.fieldValue}`
                )].join('\n') 
            ).join('\n\n'); 
            navigator.clipboard.writeText(formattedData)
                .then(() => {
                    showSuccessMessage("Details copied");
                })
                .catch(error => {
                    showErrorMessage("Error copying details");
                });
        } else {
            showErrorMessage("No data");
        }
    };

    const handleDownloadClick = () => {
        if (profileData?.sections) {
            setDownloading(true);
            const universalId = profileData.universal_id;
            const csvData = Object.keys(profileData.sections).map(sectionKey => {
                const fields = profileData.sections[sectionKey];
                return fields.map(field => ({
                    Label: field.fieldLabel,
                    Value: field.fieldValue,
                }));
            });
            const flattenedData = csvData.flat();
            const fileName = `vendor_profile_${universalId}.csv`;
            downloadCSVFile(flattenedData, fileName).then(success => {
                if (!success) {
                    setDownloadingError(true);
                    setTimeout(() => {
                        setDownloadingError(false);
                    }, 2000);
                }
            });
        } else {
            setDownloadingError(true);
            setTimeout(() => {
                setDownloadingError(false);
            }, 2000);
        }
        setTimeout(() => {
            setDownloading(false);
        }, 2000);
    };

    // * -----------------------Note Management Functions--------------------------------

    const fetchNotes = async () => {
        const data = await fetchData(`/activities/action/note?pid=${partnerId}`);
        if(_.isArray(data) && data.length > 0) setNotesData(data);
    };

    // For new ,Update and delete buttons
    const handleNewNoteClick = () => {
        setNoteAction('', '', 'add');
        setIsDailogBoxVisible(true);
    };

    const handleButtonClick = (id, value, actionType) => {
        setModalPopup(null);
        setIsDailogBoxVisible(true);
        setNoteAction(id, value, actionType);
    }

    // TODO: Repeating Code
    const handlePopupClose = () => {
        setIsDailogBoxVisible(false);
        setActiveNoteData(null);
    };

    // TODO: Repeating Code
    const setNoteAction = (id, value, actionType) => {
            if (actionType === 'edit') {
            if (!value) window.alert('You cannot set empty fields')
        } else if (actionType === 'delete') {
            if (!id) window.alert('You cannot delete this fields')
        } else if (actionType === 'add') {
            if (id) window.alert('You cannot add to this fields')
        }
        //TODO: Remove note identifier from key val pairs
        setActiveNoteData({
            noteId: id,
            noteValue: value,
            noteAction: actionType,
        });
    };

    // TODO: Repeating Code
    // handler for modal for saving new note and update note
    const handleModal = async (data={}) => {
        try {
            if (data && data.noteAction === 'delete' && data.noteId) {
                await deleteNote( data.noteId )
            } else {
                if (data && data.noteAction === 'edit' && data.noteId) {
                    await updateNote({ id: data.noteId, actionValue: data.noteValue });
                } else if (data && data.noteAction === 'add' && data.noteValue !== '') {
                    const requestBody = {
                        type: 'note',
                        pid: partnerId,
                        value: data.noteValue,
                    };
                    await createNote(requestBody);
                }
            }
            handlePopupClose();
            fetchNotes();
        } catch (error) {
            console.error('API error:', error);
        }
    };


// * ----------------------- Attachment Upload Management Functions--------------------------------

    const openUploadModal = (section, label, value) => {
        setActiveAttachmentData({ section, label, value });
        setUploadOptions([]);
        setIsUploadModal(true);
    };

    const handleNewAttachmentClick = () => {
        const _options = handleNewAttachment(attachmentsData);
        setActiveAttachmentData({});
        setUploadOptions(_options);
        setIsUploadModal(true);
    };

    const closeUploadModal = () => {
        setActiveAttachmentData({});
        setIsUploadModal(false);
    };

    const handleModalSubmit = async (file, fieldLabel, fieldKey, expiry) => {
        setIsLoading(true);
        const response = await uploadFileToDrive(file, fieldLabel, fieldKey, expiry);
        if (response.success) {
            setIsUploadModal(false);
            const _tempData = _.cloneDeep(attachmentsData)
            if (!_tempData[fieldKey]) _tempData[fieldKey] = getAttachmentTemplate(fieldLabel, fieldKey)
            _tempData[fieldKey]['fieldValue'] = response.data?.fieldValue
            _tempData[fieldKey]['fieldMeta'] = response.data?.fieldMeta
            setAttachmentsData(_tempData);
        }
        setIsLoading(false);
    };
    

// * ----------------------- Attachment Delete Management Functions--------------------------------

    const openDeleteModal = (_id, key) => {
        setAttachmentID({_id, key});
        setDeleteModal(true);
    }
    const closeDeleteModal = () => {
        setDeleteModal(false);
    }

    const handleDeleteAttachment = async(data) => {
        if(_.isEmpty(data)) return;
        try {
            const response = await deleteData(`/activities/${data._id}`, {}, 'DELETE');
            if (response.success) {
              showSuccessMessage("Deleted successfully");
              const updatedAttachmentsData = { ...attachmentsData };
              if (updatedAttachmentsData[data.key]) {
                  updatedAttachmentsData[data.key].fieldValue = "";
                  updatedAttachmentsData[data.key].fieldLabel = response?.data?.meta?.label;
                  setAttachmentsData(updatedAttachmentsData);
              }
            } else {
              showErrorMessage(response.message);
            }
          } catch (error) {
            showErrorMessage(error.message);
          } finally {
            setDeleteModal(false); 
          }

    }

// * -----------------------Identify Verification Management Functions--------------------------------

    const openVerifyModal = (fieldKey, fieldValue) => {
        setVerificationData({ [fieldKey]: fieldValue });
        setIsVerifyModal(true);
      };
    
      const closeVerifyModal = () => {
        setIsVerifyModal(false);
      };

      const handleNudgeSubmit = async (data) => {
        if(_.isEmpty(data)) return;
        const requestData = { fields: data };
        try {
          const response = await postData(`/partners/${partnerId}/vendor/fields/verify?src_entity=partners`, requestData);
          if (response.success) {
            showSuccessMessage("Request successfully submitted!!");
            if (data) {
                const fieldKey = Object.keys(data)[0];
                const updatedProfileData = { ...profileData }; 
                Object.keys(updatedProfileData.sections).forEach(sectionKey => {
                    const section = updatedProfileData.sections[sectionKey];
                    
                    section.forEach(field => {
                        if (field.fieldKey === fieldKey) {
                            field.fieldStatus = {
                                ...field.fieldStatus,
                                verification: 'Pending'
                            };
                            field.fieldStyle = {
                                ...field.fieldStyle,
                                verificationDescription: 'Verification is in process. We will notify you once it is completed.',
                                verificationColor: '#FAAD14',
                                verificationBtnCTA: 'Know more',
                                verificationIcon: 'ExclamationCircleFilled'

                            };
                        }
                    });
                });
                setProfileData(updatedProfileData); 
            }
          } else {
            showErrorMessage(response.message);
          }
        } catch (error) {
          showErrorMessage(error.message);
        } finally {
            setIsVerifyModal(false); 
        }
      };

// * -----------------------Custom Fields Management Functions--------------------------------

    const handleEditClick = (key) => {
        setIsEditing(key); 
    };
    const handleApprovalRequest = () => {
            const requestData = {
                entityId: partnerId,
                requester: userData?.id,
                entityType: "partners",
                type: "partners",
                action: 'submit'
            };
            postData(`/approvals`, requestData).then(response => {
                if (response.success) {
                    showSuccessMessage("Request successfully submitted!!");
                }
                else {
                    showErrorMessage(response.message);
                }
            }
        );
    };
    const handleSaveType = async (fieldData) => {
        if (_.isEmpty(partnerId)) return;

        const requestData = {
            pid: partnerId,
            fieldKey: fieldData.fieldKey,
            fieldLabel: fieldData.fieldLabel
          };
        const response = await postData("/partners/custom-fields", requestData);
        if(response.success){
            setIsEditing(null);
            showSuccessMessage('Label updated successfully');
        } else {
            showErrorMessage('Failed to update label');
        } 
    };

    const handleFieldLabelChange = (e, fieldKey) => {
        const { value } = e.target;
        setProfileData(prevData => {
            const updatedData = _.cloneDeep(prevData);
            _.forEach(updatedData.sections, section => {
                const fieldToUpdate = _.find(section, { fieldKey });
                if (fieldToUpdate) {
                    fieldToUpdate.fieldLabel = value;
                }
            });
            return updatedData;
        });
    };

    return (
        <section className='Home_container'>
            <section className="Home_firstContainer">
                <BreadcrumbComponent  selectedParent={selectedParent || _selectedParent} selectedChild={selectedChild || _selectedChild} additionalText={""} />
                
            </section>
            <main className='ProfileView_area'>
              
               

                <AttachFilesComponent theme={"#008065"} attachedData={attachmentsData} openUploadModal={openUploadModal} openDeleteModal={openDeleteModal} onAttachNew={handleNewAttachmentClick} openVerifyModal={openVerifyModal} partnerId={partnerId}/>
                <Button  style={{margin: "10px auto", backgroundColor: "#008065", borderColor: "#008065"}} className='ProfileView_notesButton' onClick={handleApprovalRequest}>
                    Submit for approval
                </Button>

                {/* <section className='ProfileView_container-2'>
                    <div className='ProfileView_heading'>
                        <h3>Vendor related Notes</h3>
                        <p>Only you will be able to see the notes</p>
                    </div>

                    <NotesListComponent
                        notesData={notesData}
                        modalPopup={modalPopup}
                        handleModalTrippleDot={handleModalTrippleDot}
                        handleButtonClick={handleButtonClick}
                    />

                    <div>
                        <Button className='ProfileView_notesButton' onClick={handleNewNoteClick}>
                            + New Note
                        </Button>
                    </div>
                </section> */}
            </main>
            <ModalDialogComponent 
                type={'note'}
                modalData={activeNoteData}
                isVisible={isDailogBoxVisible}
                handleModalAction={handleModal}
                onCancel={handlePopupClose}
            />
            <RequestNudgeComponent
                  type={'verify'}
                  data={verificationData}
                  isVisible={isVerifyModal}
                  onClose={closeVerifyModal}
                  handleNudgeSubmit={handleNudgeSubmit}
            />
            <RequestNudgeComponent
                type={'deleteAttachment'}
                data={attachmentID}
                isVisible={deleteModal}
                onClose={closeDeleteModal}
                handleNudgeSubmit={handleDeleteAttachment}
            />
            <ModalUploadComponent
                isVisible={isUploadModal}
                onClose={closeUploadModal}
                modalData={uploadOptions}
                setUploadData={activeAttachmentData}
                handleModalSubmit={handleModalSubmit}
                isLoading={isLoading}
            />

            {isDownloading ? (
                <div className="custom-spin">
                  <LoadingOutlined />
                  <span>Downloading Vendor details</span>
                </div>
            ) : isDownloadingError ? (
                <div className="custom-spin">
                  <ExclamationCircleFilled  style={{color: '#ff4d4f'}}/>
                  <span>Downloading failed</span>
                </div>
            ) : null}
        </section>
    );
}
