import React, { useState } from 'react';
import './style.css';
import { ExclamationCircleFilled, InboxOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import { Button, Anchor, Upload, Spin, Alert } from 'antd';
import { BASE_IMG } from '../../../constants';

const { Dragger } = Upload;
const { Link } = Anchor;
const textProcessingSpin = <Loading3QuartersOutlined spin />;
const textProcessingMessage = <span style={{ color: '#FFFFFF' }}>Processing entries</span>;

export default function BulkUploadComponent({ onCSVUpload, onStepByStepClick, visible, setVisible, handleDownloadClick, handleFileUpload }) {
    const [isProcessing, setIsProcessing] = useState(false);
    const [invalidFileType, setInvalidFileType] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    
    const handleDownload = async () => {
        const { success } = await handleDownloadClick();
        if (success) {
            setVisible(true);
        } else {
            alert('Encountered error while downloading the file');
        }
    };

    const handleUpload = async (file) => {
        const { success, errorMessage, csvData } = await handleFileUpload(file);
        if (success) {
            console.log('csvData', csvData);
            setIsProcessing(true);
            setTimeout(() => {
                onCSVUpload(csvData);
            }, 1000);
        } else {
            setInvalidFileType(true);
            setErrorMessage(errorMessage);
        }
    };

    return (
        <main className="BulkUpload_container">
            {!visible ? (
                <>
                    <div className="BulkUpload_img">
                        <div className='BulkUpload_seperateImg'>
                            <img src={`${BASE_IMG}/dashboard-img/Group.svg`} alt='csvGroup' />
                            <span>1. Download File</span>
                        </div>
                        <span className='BulkUpload_sideArrow'></span>
                        <div className='BulkUpload_seperateImg'>
                            <img src={`${BASE_IMG}/dashboard-img/edit.svg`} alt='csvGroup' />
                            <span>2. Modify File</span>
                        </div>
                        <span className='BulkUpload_sideArrow'></span>
                        <div className='BulkUpload_seperateImg'>
                            <img src={`${BASE_IMG}/dashboard-img/upload.svg`} alt='csvGroup' />
                            <span>3. Upload File</span>
                        </div>
                    </div>
                    <div className="BulkUpload_heading">
                        <h2>Bulk Upload</h2>
                    </div>
                    <div className="BulkUpload_actions">
                        <div className="BulkUpload_actionsItems">
                            <Button type="primary" onClick={handleDownload}>
                                Download csv template
                            </Button>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="BulkUpload_dragArea">
                        <Dragger name="file" accept=".csv" multiple={false} beforeUpload={handleUpload}  >
                            <div className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </div>
                            <div className="ant-upload-text">
                                <p>Click or drag file to this area to upload</p>
                            </div>
                        </Dragger>
                    </div>
                    <div className="BulkUpload_or">
                        <h3>or</h3>
                    </div>
                    <div className="BulkUpload_actions">
                        <div className="BulkUpload_actionsItems">
                            <div className="BulkUpload_buttons">
                                <Button
                                    style={{ backgroundColor: '#FFFFFF' }}
                                    onClick={handleDownload}
                                >
                                    Download CSV format
                                </Button>
                                <Upload
                                    showUploadList={true}
                                    accept=".csv"
                                    beforeUpload={handleUpload}
                                >
                                    <Button
                                        type="primary"
                                    >
                                        Upload filled csv file
                                    </Button>
                                </Upload>
                            </div>
                            {invalidFileType && (
                                <Alert
                                    className="BulkUpload_alert"
                                    message={errorMessage}
                                    type="error"
                                    icon={<div className="BulkUpload_icon"><ExclamationCircleFilled style={{ color: '#FAAD14' }} /></div>}
                                    showIcon
                                    closable
                                    style={{ marginTop: '16px' }}
                                />
                            )}
                        </div>
                    </div>
                    {isProcessing && (
                        <div className="BulkUpload_loading">
                            <Spin indicator={textProcessingSpin} tip={textProcessingMessage} />
                        </div>
                    )}
                </>
            )}
            <Link
                className='BulkUpload_link'
                href="#"
                title={
                    <span onClick={onStepByStepClick}>
                        See the step-by-step process
                        <img src={`${BASE_IMG}/dashboard-img/dashboard_icons/arrow_right_alt.svg`} alt="arrow" />
                    </span>
                }
            />
        </main>
    );
}
